import Vue from 'vue'

export default {
  state: {
    cards: {
      items: [],
      count: null
    }
  },
  mutations: {
    setCards (state, cards) {
      Vue.set(state, 'cards', cards)
    }
  },
  actions: {
    async fetchCards (ctx, params) {
      const cards = await Vue.$api.getCards({ params })
      ctx.commit('setCards', cards?.data)
    },
    async updateCardMutation (ctx, params) {
      await Vue.$api.updateCardMutation({ segments: { id: params._id }, data: params })
    },
    async deleteCardMutation (ctx, params) {
      const cards = await Vue.$api.deleteCardMutation({ segments: { id: params.id }, params: params.query })
      ctx.commit('setCards', cards?.data)
    },
  }
}
