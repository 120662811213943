import Vue from 'vue'

export default {
  state: {
  },
  mutations: {
  },
  actions: {
    async uploadFile (ctx, params) {
      const res = await Vue.$api.uploadFile({ data: params })
      return res.data
    }
  }
}
