import Vue from 'vue'

export default {
  state: {
    coins: [],
    rates: {},
    currencyType: {},
    xml: null
  },
  mutations: {
    setCoins (state, coins) {
      const KOSTUL = {
        ru: {
          SBERRUB: 'Сбербанк RUB',
          TCSBRUB: 'Т-банк RUB',
          ACRUB: 'Альфабанк RUB',
          SBPRUB: 'СБП RUB',
          RFBRUB: 'Райфайзен RUB',
          GPBRUB: 'Газпромбанк RUB',
          CARDRUB: 'Все банки RUB',
          YAMRUB: 'Юмани RUB',
          CASHRUB: 'Москва RUB',
          CASHUSD: 'Москва USD',
          CASHEUR: 'Москва EUR',
        },
        en: {
          SBERRUB: 'Sberbank RUB',
          TCSBRUB: 'T-Bank RUB',
          ACRUB: 'Alfa-Bank RUB',
          SBPRUB: 'SBP RUB',
          RFBRUB: 'Raiffeisen RUB',
          GPBRUB: 'Gazprombank RUB',
          CARDRUB: 'All bank RUB',
          YAMRUB: 'YooMoney RUB',
          CASHRUB: 'Moscow RUB',
          CASHUSD: 'Moscow USD',
          CASHEUR: 'Moscow EUR',
        }
      }
      const cookie = document.cookie.match(new RegExp('(^| )language=([^;]+)'))
      let lang = cookie ? cookie[2] : 'ru'
      const urlParams = new URLSearchParams(window.location.search);
      const queryLang = urlParams.get('lang');
      if (queryLang === 'ru' || queryLang === 'en') {
        lang = queryLang
      }
      Vue.set(state, 'coins', coins
        .sort((a, b) => a.position - b.position)
        .map(coin => {
          if (['SBERRUB', 'TCSBRUB', 'ACRUB', 'SBPRUB', 'RFBRUB', 'GPBRUB', 'CARDRUB', 'YAMRUB', 'CASHRUB', 'CASHUSD', 'CASHEUR'].includes(coin.code)) {
            coin.name = KOSTUL[lang][coin.code];
          }
          return coin; // Возвращаем coin для сохранения в новом массиве
        })
      );
      Vue.set(state, 'currencyType', coins.reduce((result, item) => {
        const key = `${item.symbol}_${item.chain}`;
        result[key] = item.name;
        return result;
      }, {}))
    },
    setRates (state, rates) {
      Vue.set(state, 'rates', rates.rates)
    },
    setXML (state, rates) {
      Vue.set(state, 'xml', rates.xml)
    }
  },
  actions: {
    async fetchCoins (ctx) {
      const coins = await Vue.$api.getCoins()
      ctx.commit('setCoins', coins?.data)
    },
    async fetchRates (ctx) {
      const rates = await Vue.$api.getRate()
      ctx.commit('setRates', rates?.data)
      ctx.commit('setXML', rates?.data)
    },
    async getRates () {
      const rates = await Vue.$api.getRate()
      return rates?.data.rates
    },

    async updateXML (ctx) {
      const rates = await Vue.$api.updateXML()
      ctx.commit('setRates', rates?.data)
      ctx.commit('setXML', rates?.data)
    },
    async updateCoinByAdmin (ctx, params) {
      const coins = await Vue.$api.updateCoinByAdmin({ segments: { id: params._id }, data: params })
      ctx.commit('setCoins', coins?.data)
      return coins?.data
    },
    async saveCoinByAdmin (ctx, params) {
      const coins = await Vue.$api.saveCoinByAdmin({ data: params })
      ctx.commit('setCoins', coins?.data)
      return coins?.data
    },
    async deleteCoinByAdmin (ctx, params) {
      const coins = await Vue.$api.deleteCoinByAdmin({ segments: { id: params } })
      ctx.commit('setCoins', coins?.data)
    },
  }
}
