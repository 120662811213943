import Vue from 'vue'

export default {
  state: {
    users: [],
    partners: [],
    user: null,
    orders: {
      items: [],
      count: null
    },
    markets: [],
    wallets: {
      pending_address: {
        items: [],
        count: null
      },
      used_address: {
        items: [],
        count: null
      }
    },
    redirects: {
      items: [],
      count: null
    },
    pages: [],
    components: [],
    bans: {
      ip: [],
      user: [],
      partner: []
    }
  },
  mutations: {
    setUsers (state, users) {
      Vue.set(state, 'users', users)
    },
    setPartners (state, partners) {
      partners = partners.map(item => ({
        ...item,
        rid: item.rid.toString()
    }))
      Vue.set(state, 'partners', partners)
    },
    setUser (state, user) {
      Vue.set(state, 'user', user)
    },
    setOrders (state, orders) {
      Vue.set(state, 'orders', orders)
    },
    setWallets (state, wallets) {
      Vue.set(state, 'wallets', wallets)
    },
    setMarkets (state, markets) {
      Vue.set(state, 'markets', markets.reverse())
    },
    setRedirects (state, redirects) {
      Vue.set(state, 'redirects', redirects)
    },
    setPages (state, pages) {
      pages.forEach(page => {
        if (!page.dictionaries.ru) page.dictionaries.ru = {}
        if (!page.dictionaries.ru.contentQuestions) page.dictionaries.ru.contentQuestions = []

        if (!page.dictionaries.en) page.dictionaries.en = {}
        if (!page.dictionaries.en.contentQuestions) page.dictionaries.en.contentQuestions = []
      })
      Vue.set(state, 'pages', pages.reverse())
    },
    setComponents (state, components) {
      Vue.set(state, 'components', components.reverse())
    },
    setBans (state, bans) {
      Vue.set(state, 'bans', bans)
    },
  },
  actions: {
    async getBans (ctx) {
      const res = await Vue.$api.getBans()
      ctx.commit('setBans', res?.data)
    },
    async blockIp (ctx, params) {
      const res = await Vue.$api.setBanIp({ data: params })
      ctx.commit('setBans', res?.data)
    },
    async removeBlockIp (ctx, params) {
      const res = await Vue.$api.removeBanIp({ data: { address: params } })
      ctx.commit('setBans', res?.data)
    },
    async blockUser (ctx, params) {
      const res = await Vue.$api.setBanUser({ data: params })
      ctx.commit('setBans', res?.data)
    },
    async removeBlockUser (ctx, params) {
      const res = await Vue.$api.removeBanUser({ data: params })
      ctx.commit('setBans', res?.data)
    },
    async blockPartner (ctx, params) {
      const res = await Vue.$api.setBanPartner({ data: params })
      ctx.commit('setBans', res?.data)
    },
    async removeBlockPartner (ctx, params) {
      const res = await Vue.$api.removeBanPartner({ data: params })
      ctx.commit('setBans', res?.data)
    },

    async getWallets (ctx, params) {
      const res = await Vue.$api.getAdminWallets({ params })
      ctx.commit('setWallets', res?.data)
    },
    async updateRatesByAdmin () {
      return await Vue.$api.updateRatesByAdmin()
    },

    async getRedirectsByAdmin (ctx, params) {
      const res = await Vue.$api.getRedirectsByAdmin({ params })
      ctx.commit('setRedirects', res?.data)
    },

    async getUsers (ctx) {
      const res = await Vue.$api.getAdminUsers()
      ctx.commit('setUsers', res?.data)
    },
    async getPartners (ctx) {
      const res = await Vue.$api.getAdminPartners()
      ctx.commit('setPartners', res?.data)
    },
    async getUserByAdmin (ctx, params) {
      const res = await Vue.$api.getUserByAdmin({ segments: { id: params } })
      return res?.data
    },
    async deleteUserByAdmin (ctx, params) {
      const res = await Vue.$api.deleteUserByAdmin({ segments: { id: params } })
      ctx.commit('setUsers', res?.data)
    },
    async updateRefStatusByAdmin (ctx, params) {
      const res = await Vue.$api.refStatusUpdate({ data: params })
      ctx.commit('setUsers', res?.data)
    },
    async getPages (ctx) {
      const res = await Vue.$api.getPagesByAdmin()
      ctx.commit('setPages', res?.data)
    },
    async getComponents (ctx) {
      const res = await Vue.$api.getComponentsByAdmin()
      ctx.commit('setComponents', res?.data)
    },
    async deletePageByAdmin (ctx, params) {
      const res = await Vue.$api.deletePageByAdmin({ segments: { id: params } })
      ctx.commit('setPages', res?.data)
    },
    async createPageByAdmin (ctx, params) {
      const res = await Vue.$api.createPageByAdmin({ data: params })
      ctx.commit('setPages', res?.data)
    },
    async updatePageByAdmin (ctx, params) {
      await Vue.$api.updatePageByAdmin({ segments: { id: params._id }, data: params })
    },
    async updateComponentByAdmin (ctx, params) {
      await Vue.$api.updateComponentByAdmin({ segments: { id: params._id }, data: params })
    },
    async getOrders (ctx, params) {
      const res = await Vue.$api.getAdminOrders({ params })
      ctx.commit('setOrders', res?.data)
    },
    async updateOrderByAdmin (ctx, params) {
      await Vue.$api.updateOrder({ segments: { id: params.id }, data: params.data })
    },
    async setWorkerByAdmin (ctx, params) {
      await Vue.$api.setWorker({ data: params })
    },
    async deleteOrderByAdmin (ctx, params) {
      const order = await Vue.$api.deleteOrder({ segments: { id: params } })
      return order.data
    },

    async getMarketsByAdmin (ctx) {
      const markets = await Vue.$api.getMarketsByAdmin()
      ctx.commit('setMarkets', markets?.data)
    },
    async getMarketByAdmin (ctx, id) {
      const market = await Vue.$api.getMarketByAdmin({ segments: { id }})
      return market.data
    },
    async saveMarketByAdmin (ctx, params) {
      const markets = await Vue.$api.saveMarketByAdmin({ data: params })
      ctx.commit('setMarkets', markets?.data)
    },
    async updateMarketByAdmin (ctx, params) {
      const markets = await Vue.$api.updateMarketByAdmin({ segments: { id: params._id }, data: params })
      ctx.commit('setMarkets', markets?.data)
    },
    async deleteMarketByAdmin (ctx, id) {
      const markets = await Vue.$api.deleteMarketByAdmin({ segments: { id } })
      ctx.commit('setMarkets', markets?.data)
    },

    async withdrawCryptoByGOD (ctx, params) {
      const response = await Vue.$api.withdrawCryptoByGOD({ data: params })
      return response
    },
    async setRoleByGOD (ctx, params) {
      await Vue.$api.setRoleByGOD({ segments: { id: params.id }, data: params.roles })
    },
    async updateUserByAdmin (ctx, params) {
      const res = await Vue.$api.updateUserByAdmin({ segments: { id: params._id }, data: params })
      ctx.commit('setUser', res?.data)
    },
    async updatePartnerByAdmin (ctx, params) {
      const res = await Vue.$api.updatePartnerByAdmin({ segments: { id: params._id }, data: params })
      ctx.commit('setPartners', res?.data)
    },
    async deletePartnerByAdmin (ctx, params) {
      const order = await Vue.$api.deletePartnerByAdmin({ segments: { id: params } })
      return order.data
    },
  }
}
