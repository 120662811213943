import axios from 'axios'

const client = axios.create({
  baseURL: process.env.VUE_APP_REST_API_BASE
})

client.interceptors.request.use(config => {
  const token = localStorage.getItem('accessToken'); // Получение токена из localStorage (или откуда у вас хранится)
  if (token) {
    config.headers.Authorization = `Bearer ${token}`; // Добавление заголовка Authorization
  }
  return config;
})

export default client
