import Vue from 'vue'
import VueApiService from 'vue-api-service'

import client from './client'
import endpoints from './endpoints'

Vue.use(VueApiService, {
  client,
  endpoints
})

export default Vue.$api
