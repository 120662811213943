import Vue from 'vue';

const fieldsTitle = {
  email: 'почту',
  phone: 'номер телефона',
  tg: 'телеграмм',
  tag: 'тэг',
  names: 'ФИО',
  wallet: 'кошелёк',
  sbpphone: 'номер СБП',
  card: 'номер карты',
  bank: 'название банка',
  bankAccountNumber: 'номер счёта',
  contactInfo: 'телефон / телеграмм'
};

Vue.prototype.$fieldsTitle = fieldsTitle;

export default fieldsTitle;