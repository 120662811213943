<template>
  <div class="is-empty">
    <slot/>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  name: 'LayoutEmpty',
  methods: {
    async initApp () {
      try {
        const match = document.cookie.match(new RegExp('(^| )language=([^;]+)'));
        const params = await this.globalParams(match ? match[2] : 'ru')
        if (!params.global.maintenance) this.$router.push({ name: 'page-main'})
      } catch (error) {
        console.log(error)
      }
    },
    ...mapActions([
      'globalParams'
    ])
  },
  created () {
    this.initApp()
  }
}
</script>
