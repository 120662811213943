<template>
  <div id="app">
    <LayoutBroker
      :layouts="$options.layouts"
      :current="$route.meta.layout"
    />
  </div>
</template>
<script>
import layouts from '@/layouts'
import LayoutBroker from 'vue-layout-broker'

export default {
  layouts,
  name: 'App',
  components: {
    LayoutBroker
  },
}
</script>
