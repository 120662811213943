import Vue from 'vue'

export default {
  state: {
    order: null
  },
  mutations: {
    setOrder (state, order) {
      Vue.set(state, 'order', order)
    }
  },
  actions: {
    async sendOrder (ctx, params) {
      const res = await Vue.$api.sendOrder({ data: params })
      return res.data
    },
    async setReceipt (ctx, params) {
      const order = await Vue.$api.setReceipt({ data: params })
      ctx.commit('setOrder', order?.data)
    },
    async fetchOrder (ctx, params) {
      const order = await Vue.$api.getOrder({ segments: { id: params } })
      ctx.commit('setOrder', order?.data)
    },
    async cancelOrder (ctx, params) {
      const order = await Vue.$api.cancelOrder({ segments: { id: params } })
      ctx.commit('setOrder', order?.data)
    },
    async userPaidOrder (ctx, params) {
      const order = await Vue.$api.userPay({ segments: { id: params } })
      ctx.commit('setOrder', order?.data)
    },
    async sendFeedback (ctx, params) {
      const order = await Vue.$api.sendFeedback({
        segments: { id: params.id },
        data: params.data
      })
      ctx.commit('setOrder', order?.data)
    },
    async setCardToOrderMutation (ctx, params) {
      const order = await Vue.$api.setPayCard({ segments: { id: params.orderId }, data: { cardId: params.cardId } })
      ctx.commit('setOrder', order?.data)
    },
  }
}
