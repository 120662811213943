import Vue from 'vue'

export default {
  state: {
    user: null,
    isAuthenticated: false
  },
  mutations: {
    setToken (state, token) {
      localStorage.accessToken = token
    },
    setUser (state, user) {
      Vue.set(state, 'user', user)
      Vue.set(state, 'isAuthenticated', true)
    },
    logOut (state) {
      localStorage.removeItem('accessToken')
      Vue.set(state, 'user', null)
      Vue.set(state, 'isAuthenticated', false)
    }
  },
  actions: {
    async initUserMutation(ctx, params) {
      const res = await Vue.$api.initUser({ data: params })
      const token = res?.data?.token
      ctx.commit('setToken', token)
      await ctx.dispatch('getUser')
    },
    async registration (ctx, params) {
      const res = await Vue.$api.registration({ data: params })
      const token = res?.data?.token
      ctx.commit('setToken', token)
      await ctx.dispatch('getUser')
    },
    async registrationPartner (ctx, params) {
      const res = await Vue.$api.registrationPartner({ data: params })
      const token = res?.data?.token
      ctx.commit('setToken', token)
      await ctx.dispatch('getPartner')
    },
    async login (ctx, params) {
      const res = await Vue.$api.login({ data: params })
      const token = res?.data?.token
      ctx.commit('setToken', token)
      if (res?.data?.roles[0] === 'MONITORING') {
        await ctx.dispatch('getPartner')
      } else {
        await ctx.dispatch('getUser')
      }
    },
    async resetPassword (ctx, params) {
      await Vue.$api.resetPassword({ data: params })
    },
    async getUser (ctx) {
      const res = await Vue.$api.getUser()
      ctx.commit('setUser', res?.data)
    },
    async getPartner (ctx) {
      const res = await Vue.$api.getPartner()
      ctx.commit('setUser', res?.data)
    },
    async updateUser (ctx, params) {
      const res = await Vue.$api.updateUser({ data: params })
      ctx.commit('setUser', res.data)
    },
    async updatePassword (ctx, params) {
      await Vue.$api.updatePassword({ data: params })
    },
    async updatePasswordPartner (ctx, params) {
      await Vue.$api.updatePasswordPartner({ data: params })
    },
    async deleteUser (ctx) {
      await Vue.$api.deleteUser()
      ctx.commit('logOut')
    },
    async getReferralCode (ctx) {
      const res = await Vue.$api.getReferralCode()
      ctx.commit('setUser', res?.data)
    },
    async refWithdraw (ctx, params) {
      const res = await Vue.$api.refWithdraw({ data: params })
      ctx.commit('setUser', res?.data)
    },
    async refWithdrawPartner (ctx, params) {
      const res = await Vue.$api.refWithdrawPartner({ data: params })
      ctx.commit('setUser', res?.data)
    }
  }
}
