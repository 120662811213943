import Vue from 'vue'
import Vuex from 'vuex'
import coin from './modules/coin'
import auth from './modules/auth'
import file from './modules/file'
import order from './modules/order'
import global from './modules/global'
import admin from './modules/admin'
import cards from './modules/cards'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    coin,
    auth,
    file,
    order,
    global,
    admin,
    cards
  }
})
